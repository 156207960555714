import { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';

import * as application from 'store/application';
import type { IViewModel } from 'controller/types';
import type { IRootStore } from 'store/types';

import settingsParams from '../settingsParams';

type Resolvables = {
  groupId?: string;
  persisted: boolean;
  items?: IFeedItem[];
};

export function resolve(
  vm: IViewModel,
  store: IRootStore,
  params: ControllerParams,
) {
  const { settings } = params.flowAPI;
  const { isMobile, isSSR } = params.flowAPI.environment;

  return async function (resolvables?: Resolvables): Promise<Resolvables> {
    const slugOrId = settings.get(settingsParams.groupId);
    const limit = settings.get(settingsParams.postsCount);

    if (resolvables?.persisted) {
      return resolvables;
    }

    const [response] = await Promise.all([
      vm.group$.fetch(slugOrId).unwrap(),
      vm.application$.fetchUserProfile(),
    ]);

    const group = response.group;

    if (!group?.id) {
      return { persisted: true };
    }

    const { items } = await vm.feed$.fetch(group.id, { limit }, !isMobile);

    store.dispatch(application.actions.setAppData({ groupId: group.id }));

    return {
      items,
      persisted: true,
      groupId: group.id,
    };
  };
}
